import { useEffect, useState } from "react";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { AiOutlineMenu } from "react-icons/ai";
import { connectWallet, getCurrentWalletConnected } from "../utils/interact";

function Nav() {

  const theme = useTheme();
  const [drawerState, setDrawerState] = useState(false);
  const [connectButtonName, setConnectButtonName] = useState("Connect Wallet");
  const toggleDrawer = (open) => (event) => {
    setDrawerState(open);
  };

  return (
    <StyledNav>
      <div className="nav-logo">
        {useMediaQuery(theme.breakpoints.down("lg")) && <h4>MatchBlox</h4>}
      </div>
      {useMediaQuery(theme.breakpoints.down("lg")) ? (
        <Drawer anchor="right" open={drawerState} onClose={toggleDrawer(false)}>
          <Box
            style={{
              width: "250px",
              background: "black",
              backgroundColor: "black",
              height: "100vh",
              paddingTop: "70px",
            }}
            onClick={toggleDrawer(false)}
          >
            <Link
              onClick={toggleDrawer(false)}
              style={{ display: "block", margin: "20px" }}
              to="/"
            >
              Home
            </Link>
            <Link
              onClick={toggleDrawer(false)}
              style={{ display: "block", margin: "20px" }}
              to="laboratory"
            >
              Collections
            </Link>
            <Link
              onClick={toggleDrawer(false)}
              style={{ display: "block", margin: "20px" }}
              to="roadmap"
            >
              Roadmap
            </Link>
            <Link
              onClick={toggleDrawer(false)}
              style={{ display: "block", margin: "20px" }}
              to="utility"
            >
              Utility
            </Link>
            <Link
              onClick={toggleDrawer(false)}
              style={{ display: "block", margin: "20px" }}
              to="game-time"
            >
              Game Time
            </Link>
          </Box>
        </Drawer>
      ) : (
        <div className="nav-tabs">
          <Link to="laboratory">Collections</Link>
          <Link to="roadmap">Roadmap</Link>
          <Link to="/">
            <h1>MatchBlox</h1>
          </Link>
          <Link to="utility">Utility</Link>
          <Link to="game-time">Game Time</Link>
        </div>
      )}
      <div className="nav-buttons">
        <button
          onClick={(event) =>
            connectWallet().then((res) => {
              if (res.status === "Wallet already connected")
                setConnectButtonName("Wallet Connected");
            })
          }
          className="connect-wallet"
        >
          {connectButtonName}
        </button>
        {useMediaQuery(theme.breakpoints.down("lg")) && (
          <button className="nav-hamburger" onClick={toggleDrawer(true)}>
            <AiOutlineMenu />
          </button>
        )}
      </div>
    </StyledNav>
  );
}

const StyledNav = styled.div`
  min-height: 10vh;
  z-index: 10;
  padding: 1% 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: black;
  .nav-logo,
  .nav-tabs,
  .nav-buttons {
    display: flex;
    align-items: center;
  }
  .nav-logo {
    img {
      width: 50px;
    }
  }
  .nav-tabs {
    flex: 1 1 40%;
    justify-content: space-evenly;
    white-space: nowrap;
    a {
      margin-right: 2rem;
      transition: all 0.2s ease;
      &:hover {
        transform: scale(1.05);
      }
      h1 {
        transition: all 0.3s ease;
        &:hover {
          transform: scale(1.05) rotate(-2deg);
        }
      }
    }
  }
  .nav-buttons {
    flex: 1 1 25%;
    justify-content: flex-end;
    button {
      padding: 10px 16px;
      min-width: 40px;
      border-radius: 50px;
      white-space: nowrap;
    }
    .volume__button {
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 25px;
        height: 25px;
      }
    }
    button,
    a {
      margin-left: 1.6rem;
    }
    a {
      display: flex;
      padding: 1rem;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
    }
    .nav-hamburger {
      background: transparent;
      border: none;
      padding: 0;
      color: white;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
  @media (min-width: 1200px) {
    .nav-logo {
      flex: 1 1 25%;
    }
  }
  @media (max-width: 600px) {
    padding: 1% 2%;
    .nav-buttons {
      button {
        margin-left: 0;
        padding: 5px 7px;
      }
    }
  }
`;

export default Nav;
