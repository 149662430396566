import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import React from "react";
import styled from "styled-components";
import { pageAnimation } from "../styles/styles";
import { AiFillInstagram } from "react-icons/ai";
import { SiDiscord, SiTwitter } from "react-icons/si";
import video from "../assets/video.mp4";
import one from "../assets/homeArt/one.png";
import two from "../assets/homeArt/two.png";
import three from "../assets/homeArt/three.png";
import four from "../assets/homeArt/four.png";

function Home() {
  return (
    <StyledHome>
      <AnimatePresence>
        <motion.div
          variants={pageAnimation}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <div className="video-div">
            <video src={video} autoPlay muted loop />
          </div>
          <div className="content">
            <h2>MatchBlox</h2>
          </div>
          <div className="socials">
            <a href="https://twitter.com/matchbloxnft">
              <SiTwitter />
            </a>
            <a href="https://discord.gg/ckpQ3bmc">
              <SiDiscord />
            </a>
            <a href="https://www.instagram.com/matchbloxnft/">
              <AiFillInstagram />
            </a>
          </div>
        </motion.div>
      </AnimatePresence>
    </StyledHome>
  );
}

const StyledHome = styled.div`
background: black;
  .video-div {
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &:before {
      content: "";
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }
  div {
    height: 89vh;
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      transition: all 0.5s ease;
      cursor: pointer;
      z-index: 10;
      &:hover {
        transform: scale(1.5) rotate(-2deg);
      }
    }
    .img {
      position: absolute;
      opacity: 0;
      transition: all 0.3s ease;
      animation-name: leftTransition;
      animation-duration: 2s;
    }
    .socials {
      position: absolute;
      bottom: -7%;
      left: 50%;
      transform: translate(-50%, -10%);
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      z-index: 5;
      a {
        width: 25px;
        height: 25px;
        margin: 1rem;
        transition: all 0.2s ease;
        &:hover {
          transform: scale(1.2) rotate(-2deg);
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  @keyframes leftTransition {
    50% {
      opacity: 1;
    }
    100% {
      left: -50%;
    }
  }
`;

export default Home;
