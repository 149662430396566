import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import React, { useState } from "react";
import styled from "styled-components";
import { pageAnimation } from "../styles/styles";
import bgImg from "../assets/bg.png";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Heading } from "../styles/styles";
import { useMediaQuery, useTheme } from "@mui/material";
import raceFlag from "../assets/raceFlag.png"
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.min.css";

SwiperCore.use([Pagination, Navigation, Autoplay]);

function Roadmap() {
  const theme = useTheme();
  return (
    <RoadmapSection raceFlag={raceFlag}>
      <AnimatePresence>
        {/* <motion.img
          variants={bgAnimation}
          initial="hidden"
          animate="show"
          exit="exit"
          className="bg__img"
          src={bgImg}
        /> */}
        <motion.div
          variants={pageAnimation}
          initial="hidden"
          animate="show"
          exit="exit"
          className="section"
          key="modal"
        >
          {useMediaQuery(theme.breakpoints.up("sm")) ? (
            <StyledRoadmap>
              <Heading>
                <h3>Roadmap</h3>
              </Heading>
              <div className="timeline">
                <Timeline position="alternate">
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{ py: 0.8, px: 1.1, backgroundColor: "#0089ca" }}
                      >
                        <h4>01</h4>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: 0, px: 2 }}>
                      <br />
                      <h4>Open MATCHBLOX</h4>
                      <ul>
                        <li>Our MatchBLOX project opens its doors! </li>
                        <li>
                          Welcome to the MATCHBLOX community everyone! In here,
                          car enthusiasts, gamers, race fans and NFT collectors
                          can Join in to share the amazing adventures that we
                          have planned for you. We are ready to show ourselves
                          to the world and conquer the blockchain with our
                          MatchBLOX NFTs.
                        </li>
                        <li>
                          As a little teaser for you, what differentiates us to
                          other NFT collections is that MatchBLOX has already
                          purchased multiple plots of “LANDS” in the SANDBOX!!
                          We have also started to work on a playable demo for
                          the game based around driving and racing in the
                          Sandbox. We are here to deliver for you all!
                        </li>
                      </ul>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{ py: 0.8, px: 0.8, backgroundColor: "#0089ca" }}
                      >
                        <h4>02</h4>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: 0, px: 2 }}>
                      <br />
                      <h4>The Exclusives</h4>
                      <ul>
                        <li>
                          One week before our generation one mint we have
                          something special for you! There is a secret
                          collection of 20 one of one’s. We will kick of with an
                          auction in where we want to give everyone the
                          opportunity to own one of the most exclusive NFT
                          MatchBLOX has to offer. Keep your eye on the discord!
                          More on this coming soon.
                        </li>
                      </ul>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{ py: 0.8, px: 0.8, backgroundColor: "#0089ca" }}
                      >
                        <h4>03</h4>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: 0, px: 2 }}>
                      <br />
                      <h4>Release Generation 1 VIP sale and (Q3-22)</h4>
                      <ul>
                        <li>
                          Now we will release the first generation of 2,500 NFTs
                          to our VIP community which have shown their loyalty.
                          There is no whitelist for this generation as we will
                          carefully select who can become a Generation 1
                          investor. The NFTs are your keys to the MatchBLOX
                          world located in the SANDBOX GAME!. In the SANDBOX
                          you’ll have the ability to visit the showroom and
                          acquire SANDBOX assets in the metaverse, which
                          includes, cars, upgrades, avatar customizations.
                          What’s even BETTER is that these assets will be
                          attached to your NFTs, which will increase the value
                          of them!
                        </li>
                        <li>
                          We will also release our first merchandise which can
                          be bought or won during fun giveaways and competitions
                          in our game channels. At the same time, we will finish
                          the demo of our game and will release it for the
                          Generation 1 holders to be able to try it out.
                        </li>
                      </ul>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{ py: 0.8, px: 0.8, backgroundColor: "#0089ca" }}
                      >
                        <h4>04</h4>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: 0, px: 2 }}>
                      <br />
                      <h4>Release Generation 2, 3, 4, and 5 (Q3-22)</h4>
                      <ul>
                        <li>
                          At this point we will release Generation 2 which will
                          comprise of 2,500 NFTs. We will reserve 100 whitelist
                          spots for those of you who have met the criteria,
                          shown engagement, brought positivity to the community
                          and leveled up by being active in the discord and as
                          well as being active on our social media. You will be
                          able to see more details on these criteria in the
                          #whitelistcriteria channel.
                        </li>
                      </ul>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{ py: 0.8, px: 0.8, backgroundColor: "#0089ca" }}
                      >
                        <h4>05</h4>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: 0, px: 2 }}>
                      <br />
                      <h4>
                        Finalize playable demo of game & acquire more LANDS, and
                        expand SANDBOX experiences(Q4-22)
                      </h4>
                      <ul>
                        <li>
                          The NFTs are your keys to the MatchBLOX world located
                          in the SANDBOX. You’ll have the ability to visit the
                          showroom and acquire SANDBOX assets in the metaverse,
                          which includes, cars, upgrades, avatar customizations,
                          and more that will be attached to your NFTs!
                        </li>
                        <li>
                          We will finish the demo of our game and will release
                          it for the Generation 1 holders to be able to try it
                          out. We can also use the game and “rent” the
                          experience out to other land owners, they can come to
                          our land and test drive the experience as well!
                        </li>
                        <li>
                          As stated before, we will fund a marketing campaign to
                          promote MatchBLOX. Reaching out to people from the
                          car, racing and gaming industry, not just to promote
                          the project, but to JOIN the project!
                        </li>
                        <li>
                          We will buy more “LANDS” to add to our initial SANDBOX
                          land so we can build more tracks and increase
                          difficulty. And we will build metaverse experiences on
                          our own land for our community, but also monetize it
                          to other landowners/renters! This will bring more
                          revenue streams for us to re-invest in the project.
                        </li>
                      </ul>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{ py: 0.8, px: 0.8, backgroundColor: "#0089ca" }}
                      >
                        <h4>06</h4>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: 0, px: 2 }}>
                      <br />
                      <h4>Start development of game, built in UE5 (Q4-22)</h4>
                      <ul>
                        <li>
                          We will utilize the capital invested with us to create
                          a brand new game on UE5 by working with a AAA game
                          developer. The game will be made to be fun and
                          competitive… It will DEFINITELY have state of the art
                          graphics (on the UE5 ENGINE!). This is where we will
                          create the game, have in-game purchases (similar to
                          the assets we’ll have in the sandbox). All Revenue of
                          this game will go to NFT holders of this collection!
                        </li>
                      </ul>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{ py: 0.8, px: 0.8, backgroundColor: "#0089ca" }}
                      >
                        <h4>07</h4>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: 0, px: 2 }}>
                      <br />
                      <h4>Game Time</h4>
                      <ul>
                        <li>
                          We will launch a fully playable version of the
                          MATCHBLOX car racing game! With proper network code
                          and playability. We will sell this game on steam, and
                          profits will go to NFT holders!
                        </li>
                      </ul>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </div>
            </StyledRoadmap>
          ) : (
            <StyledSwiper>
              <h3>Roadmap</h3>
              <Swiper
                className="swiper-div"
                modules={[EffectFade]}
                effect="fade"
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: true,
                }}
                autoplayDisableOnInteraction={true}
                spaceBetween={50}
                slidesPerView={1}
                pagination={true}
                loop={true}
              >
                <SwiperSlide>
                  <h4>01-Open MATCHBLOX</h4>
                  <ul>
                    <li>Our MatchBLOX project opens its doors! </li>
                    <li>
                      Welcome to the MATCHBLOX community everyone! In here, car
                      enthusiasts, gamers, race fans and NFT collectors can Join
                      in to share the amazing adventures that we have planned
                      for you. We are ready to show ourselves to the world and
                      conquer the blockchain with our MatchBLOX NFTs.
                    </li>
                    <li>
                      As a little teaser for you, what differentiates us to
                      other NFT collections is that MatchBLOX has already
                      purchased multiple plots of “LANDS” in the SANDBOX!! We
                      have also started to work on a playable demo for the game
                      based around driving and racing in the Sandbox. We are
                      here to deliver for you all!
                    </li>
                  </ul>
                </SwiperSlide>
                <SwiperSlide>
                  <h4>02-The Exclusives</h4>
                  <ul>
                    <li>
                      One week before our generation one mint we have something
                      special for you! There is a secret collection of 20 one of
                      one’s. We will kick of with an auction in where we want to
                      give everyone the opportunity to own one of the most
                      exclusive NFT MatchBLOX has to offer. Keep your eye on the
                      discord! More on this coming soon.
                    </li>
                  </ul>
                </SwiperSlide>
                <SwiperSlide>
                  <h4>03-Release Generation 1 VIP sale and (Q3-22)</h4>
                  <ul>
                    <li>
                      Now we will release the first generation of 2,500 NFTs to
                      our VIP community which have shown their loyalty. There is
                      no whitelist for this generation as we will carefully
                      select who can become a Generation 1 investor. The NFTs
                      are your keys to the MatchBLOX world located in the
                      SANDBOX GAME!. In the SANDBOX you’ll have the ability to
                      visit the showroom and acquire SANDBOX assets in the
                      metaverse, which includes, cars, upgrades, avatar
                      customizations. What’s even BETTER is that these assets
                      will be attached to your NFTs, which will increase the
                      value of them!
                    </li>
                    <li>
                      We will also release our first merchandise which can be
                      bought or won during fun giveaways and competitions in our
                      game channels. At the same time, we will finish the demo
                      of our game and will release it for the Generation 1
                      holders to be able to try it out.
                    </li>
                  </ul>
                </SwiperSlide>
                <SwiperSlide>
                  <h4>04-Release Generation 2, 3, 4, and 5 (Q3-22)</h4>
                  <ul>
                    <li>
                      At this point we will release Generation 2 which will
                      comprise of 2,500 NFTs. We will reserve 100 whitelist
                      spots for those of you who have met the criteria, shown
                      engagement, brought positivity to the community and
                      leveled up by being active in the discord and as well as
                      being active on our social media. You will be able to see
                      more details on these criteria in the #whitelistcriteria
                      channel.
                    </li>
                  </ul>
                </SwiperSlide>
                <SwiperSlide>
                  <h4>
                    05-Finalize playable demo of game & acquire more LANDS, and
                    expand SANDBOX experiences(Q4-22)
                  </h4>
                  <ul>
                    <li>
                      The NFTs are your keys to the MatchBLOX world located in
                      the SANDBOX. You’ll have the ability to visit the showroom
                      and acquire SANDBOX assets in the metaverse, which
                      includes, cars, upgrades, avatar customizations, and more
                      that will be attached to your NFTs!
                    </li>
                    <li>
                      We will finish the demo of our game and will release it
                      for the Generation 1 holders to be able to try it out. We
                      can also use the game and “rent” the experience out to
                      other land owners, they can come to our land and test
                      drive the experience as well!
                    </li>
                    <li>
                      As stated before, we will fund a marketing campaign to
                      promote MatchBLOX. Reaching out to people from the car,
                      racing and gaming industry, not just to promote the
                      project, but to JOIN the project!
                    </li>
                    <li>
                      We will buy more “LANDS” to add to our initial SANDBOX
                      land so we can build more tracks and increase difficulty.
                      And we will build metaverse experiences on our own land
                      for our community, but also monetize it to other
                      landowners/renters! This will bring more revenue streams
                      for us to re-invest in the project.
                    </li>
                  </ul>
                </SwiperSlide>
                <SwiperSlide>
                  <h4>06-Start development of game, built in UE5 (Q4-22)</h4>
                  <ul>
                    <li>
                      We will utilize the capital invested with us to create a
                      brand new game on UE5 by working with a AAA game
                      developer. The game will be made to be fun and
                      competitive… It will DEFINITELY have state of the art
                      graphics (on the UE5 ENGINE!). This is where we will
                      create the game, have in-game purchases (similar to the
                      assets we’ll have in the sandbox). All Revenue of this
                      game will go to NFT holders of this collection!
                    </li>
                  </ul>
                </SwiperSlide>
                <SwiperSlide>
                  <h4>07-Game Time</h4>
                  <ul>
                    <li>
                      We will launch a fully playable version of the MATCHBLOX
                      car racing game! With proper network code and playability.
                      We will sell this game on steam, and profits will go to
                      NFT holders!
                    </li>
                  </ul>
                </SwiperSlide>
              </Swiper>
            </StyledSwiper>
          )}
        </motion.div>
      </AnimatePresence>
    </RoadmapSection>
  );
}

const RoadmapSection = styled.div`
  overflow: hidden;
  background: url(${(props) => props.raceFlag});
  background-position: center;
  .bg__img {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
  }
`;

const StyledRoadmap = styled.div`
  padding: 10rem 0;
  .roadmap-content {
    width: 60%;
    margin: auto;
    text-align: center;
  }
  .timeline {
    width: 60%;
    margin: auto;
    li {
      font-size: 14px;
    }
  }
  .ride {
    width: 60%;
    margin: auto;
    text-align: center;

    button {
      padding: 10px 16px;
      min-width: 40px;
    }
  }
  @media (max-width: 800px) {
    .timeline {
      width: 100%;
      li {
        list-style: circle;
      }
    }
    .roadmap-content {
      width: 80%;
    }
    .ride {
      width: 80%;
    }
  }
`;

const StyledSwiper = styled.div`
  h3 {
    text-align: center;
  }
  h4 {
    margin-bottom: 2rem;
  }
  padding: 6rem 0;
  .swiper-div {
    padding: 1rem;
    .swiper-slide {
      padding: 2rem;
    }
    .swiper-pagination-bullet {
      background: white !important;
    }
    .swiper-button-next,
    .swiper-button-prev {
      color: white;
    }
  }
`;

export default Roadmap;
