import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import React from "react";
import styled from "styled-components";
import { bgAnimation, pageAnimation } from "../styles/styles";
import bg2 from "../assets/bg2.png";
import raceFlag from "../assets/raceFlag.png";
import { useMediaQuery, useTheme } from "@mui/material";

function Laboratory() {
  const theme = useTheme();

  return (
    <StyledLaboratory raceFlag={raceFlag}>
      <AnimatePresence>
        {useMediaQuery(theme.breakpoints.up("lg")) && (
          <motion.img
            variants={bgAnimation}
            initial="hidden"
            animate="show"
            exit="exit"
            className="bg__img"
            src={bg2}
          />
        )}
        <motion.div
          variants={pageAnimation}
          initial="hidden"
          animate="show"
          exit="exit"
          className="content"
        >
          <h3>Collections</h3>
          {/* <iframe
            src={`https://www.youtube.com/embed/faEbrfhigl4`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          /> */}
          <ul>
            <li>MatchBLOX will consist of 2 collections.</li>
            <li>
              The first being "THE EXCLUSIVES"... We don't want to give away too
              much about these, but they will be ICONIC NFT's.
            </li>
            <li>
              There will be a special process so keep close to the project and
              see how to get your hands on one.
            </li>
            <li>
              The main collection is a 10k collection, the artwork will be based
              on ANIME styled artwork.
            </li>
          </ul>
        </motion.div>
      </AnimatePresence>
    </StyledLaboratory>
  );
}

const StyledLaboratory = styled.div`
  background: url(${(props) => props.raceFlag});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .bg__img {
    position: absolute;
    top: -5%;
    left: 65%;
    width: 40%;
    z-index: 5;
  }
  .content {
    min-height: 90vh;
    width: 40%;
    margin-inline: auto;
    display: flex;
    align-items: center;
    z-index: 10;
    /* justify-content: center; */
    flex-direction: column;
    gap: 4rem;
    h3 {
      margin-top: 10rem;
    }
    iframe {
      width: 50%;
      min-height: 400px;
      border-radius: 12px;
    }
    @media (max-width: 600px) {
      iframe {
        width: 90%;
        min-height: 300px;
      }
    }
  }
`;

export default Laboratory;
