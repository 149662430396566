import { Route, Routes } from "react-router";
import Home from "./components/Home";
import Laboratory from "./components/Laboratory";
import GameTime from "./components/GameTime";
import Nav from "./components/Nav";
import Roadmap from "./components/Roadmap";
import Utility from "./components/Utility";

function App() {
  return (
    <>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/laboratory" element={<Laboratory />} />
        <Route path="/utility" element={<Utility />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/game-time" element={<GameTime />} />
      </Routes>
    </>
  );
}

export default App;
