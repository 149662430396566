import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import React, { useState } from "react";
import styled from "styled-components";
import {
  bgAnimation,
  containerAnimation,
  pageAnimation,
} from "../styles/styles";
import bgImg from "../assets/bg.png";
import raceFlag from "../assets/raceFlag.png";
import { useMediaQuery, useTheme } from "@mui/material";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.min.css";

SwiperCore.use([Pagination, Navigation, Autoplay]);

function Utility() {
  const [description, setDescription] = useState(1);
  const theme = useTheme();

  return (
    <StyledUtility raceFlag={raceFlag}>
      <AnimatePresence>
        {useMediaQuery(theme.breakpoints.up("md")) ? (
          <>
            <motion.img
              variants={bgAnimation}
              initial="hidden"
              animate="show"
              exit="exit"
              className="bg__img"
              src={bgImg}
            />
            <motion.div
              variants={pageAnimation}
              initial="hidden"
              animate="show"
              exit="exit"
              key="modal"
            >
              <div className="section">
                <h3>Utility</h3>
                <div className="container">
                  <div className="tabs">
                    <h5
                      className={description === 1 ? "active" : "not"}
                      onClick={() => setDescription(1)}
                    >
                      The Driver
                    </h5>
                    <h5
                      className={description === 2 ? "active" : "not"}
                      onClick={() => setDescription(2)}
                    >
                      MatchBLOX Land
                    </h5>
                    <h5
                      className={description === 3 ? "active" : "not"}
                      onClick={() => setDescription(3)}
                    >
                      In-Game Rewards
                    </h5>
                  </div>
                  <div className="description">
                    {description === 1 ? (
                      <motion.div
                        variants={containerAnimation}
                        initial="hidden"
                        animate="show"
                        exit="exit"
                        key={description}
                      >
                        <h4>The Driver</h4>
                        <p>
                          Your driver is your main NFT and doubles as your
                          player access card to the MatchBLOX land inside the
                          Sandbox. The drivers will come in different
                          categories. Track king, Street king, Drift king,
                          Street queen, Drift queen, Streetracer, Getaway
                          Driver, and Hero. They will compete in races as member
                          of one of four fractions. Street Racer, Track Star,
                          Drifter, Transporter.
                        </p>
                      </motion.div>
                    ) : description === 2 ? (
                      <motion.div
                        variants={containerAnimation}
                        initial="hidden"
                        animate="show"
                        exit="exit"
                        key={description}
                      >
                        <h4>MatchBLOX Land</h4>
                        <p>
                          By holding a MatchBLOX driver NFT you will gain
                          exclusive access to the MatchBLOX land located in the
                          Sandbox. There you will find ‘The Showroom’ where you
                          can buy Sandbox assets like cars you can drive and
                          race on the different tracks we have build. You can
                          compete against another holder or take part in the
                          competitions. You can also buy other assets such as
                          tuning parts to improve your car, wheels to enhance
                          the look and handling or even driver customizations.
                        </p>
                      </motion.div>
                    ) : (
                      description === 3 && (
                        <motion.div
                          variants={containerAnimation}
                          initial="hidden"
                          animate="show"
                          exit="exit"
                          key={description}
                        >
                          <h4>In-Game Rewards</h4>
                          <p>
                            Your MatchBLOX NFT drivers will possess the ability
                            to “stake” immediately through the sandbox. Staking
                            income can also be used to improve a holder's NFT by
                            modifying their assets such as vehicles with such
                            upgrades as “Turbos” or “Tuning” or “New wheels”
                            etc.
                          </p>
                        </motion.div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        ) : (
          <StyledSwiper>
            <h3>Utility</h3>
            <Swiper
              className="swiper-div"
              modules={[EffectFade]}
              effect="fade"
              autoplay={{
                delay: 5000,
                disableOnInteraction: true,
              }}
              autoplayDisableOnInteraction={true}
              spaceBetween={50}
              slidesPerView={1}
              pagination={true}
              loop={true}
            >
              <SwiperSlide>
                <h4>The Driver</h4>
                <p>
                  Your driver is your main NFT and doubles as your player access
                  card to the MatchBLOX land inside the Sandbox. The drivers
                  will come in different categories. Track king, Street king,
                  Drift king, Street queen, Drift queen, Streetracer, Getaway
                  Driver, and Hero. They will compete in races as member of one
                  of four fractions. Street Racer, Track Star, Drifter,
                  Transporter.
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <h4>MatchBLOX Land</h4>
                <p>
                  By holding a MatchBLOX driver NFT you will gain exclusive
                  access to the MatchBLOX land located in the Sandbox. There you
                  will find ‘The Showroom’ where you can buy Sandbox assets like
                  cars you can drive and race on the different tracks we have
                  build. You can compete against another holder or take part in
                  the competitions. You can also buy other assets such as tuning
                  parts to improve your car, wheels to enhance the look and
                  handling or even driver customizations.
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <h4>In-Game Rewards</h4>
                <p>
                  Your MatchBLOX NFT drivers will possess the ability to “stake”
                  immediately through the sandbox. Staking income can also be
                  used to improve a holder's NFT by modifying their assets such
                  as vehicles with such upgrades as “Turbos” or “Tuning” or “New
                  wheels” etc.
                </p>
              </SwiperSlide>
            </Swiper>
          </StyledSwiper>
        )}
      </AnimatePresence>
    </StyledUtility>
  );
}

const StyledUtility = styled.div`
  height: 90vh;
  overflow: hidden;
  background: url(${(props) => props.raceFlag});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .bg__img {
    position: absolute;
    top: -5%;
    left: 65%;
    width: 40%;
    z-index: 2;
  }
  .section {
    display: flex;
    align-items: center;
    flex-direction: column;
    h3 {
      margin-top: 10rem;
      margin-bottom: 2rem;
    }
    .container {
      display: flex;
      flex-direction: column;
      width: 45%;
      height: 60vh;
      gap: 2rem;
      padding-top: 2rem;
      .tabs {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        h5 {
          background-color: #0284c750;
          padding: 1rem 1.4rem;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.3s ease;
          &:hover {
            background-color: #0089ca;
          }
        }
        .active {
          background-color: #0089ca;
        }
      }
      .description {
        padding: 4rem;
        border-radius: 12px;
        background-color: #0284c750;
        /* background: linear-gradient(
          to right bottom,
          rgba(255, 255, 255, 0.7),
          rgba(255, 255, 255, 0.3)
        ); */
        /* filter: blur(10px); */
        height: 50vh;
        h4 {
          margin-bottom: 2rem;
        }
      }
    }
  }
`;

const StyledSwiper = styled.div`
  h3 {
    text-align: center;
  }
  h4 {
    margin-bottom: 2rem;
  }
  padding: 6rem 0;
  .swiper-div {
    padding: 1rem;
    .swiper-slide {
      padding: 2rem;
    }
    .swiper-pagination-bullet {
      background: white !important;
    }
    .swiper-button-next,
    .swiper-button-prev {
      color: white;
    }
  }
`;

export default Utility;
