import styled from "styled-components";

export const Heading = styled.div`
  text-align: center;
`;

export const pageAnimation = {
  hidden: { x: 20, opacity: 0 },
  show: { x: 0, opacity: 1, transition: { duration: 0.8 } },
  exit: { x: -20, opacity: 0, transition: { duration: 0.8 }},
};

export const containerAnimation = {
  hidden: { y: 20, opacity: 0 },
  show: { y: 0, opacity: 1, transition: { duration: 0.3 } },
  exit: { y: -20, opacity: 0 },
};

export const bgAnimation = {
  hidden: { y: "100%", opacity: 0 },
  show: { y: "22%", opacity: 1, transition: { delay: 0.4, duration: 1 } },
  exit: { opacity: 0 },
};
