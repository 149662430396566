import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import React from "react";
import styled from "styled-components";
import { pageAnimation } from "../styles/styles";

function GameTime() {
  return (
    <StyledHome>
      <AnimatePresence>
        <motion.div
          variants={pageAnimation}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <div className="content">
            <h2>Coming Soon</h2>
          </div>
        </motion.div>
      </AnimatePresence>
    </StyledHome>
  );
}

const StyledHome = styled.div`
background: black;
  div {
    height: 89vh;
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      transition: all 0.5s ease;
      cursor: pointer;
      z-index: 10;
      &:hover {
        transform: scale(1.5) rotate(-2deg);
      }
    }
    .img {
      position: absolute;
      opacity: 0;
      transition: all 0.3s ease;
      animation-name: leftTransition;
      animation-duration: 2s;
    }
  }
  @keyframes leftTransition {
    50% {
      opacity: 1;
    }
    100% {
      left: -50%;
    }
  }
`;

export default GameTime;
